<template>
  <main class="max-w-4xl mx-auto p-4">
    <h1 class="text-3xl mb-2">Privacy Policy</h1>

    <div class="mb-2">
      <h2 class="text-xl">Effective Date: May 8, 2024</h2>
      <p>This Privacy Policy applies to the GPT's developed by Intelliminds. At Intelliminds, we are committed to protecting your privacy and handling any personal information we obtain from you with care and respect.</p>
    </div>
    <div class="mb-2">
      <h2 class="text-xl">Data Collection and Use:</h2>
      <p>Our GPT's do not collect, use, or share any personal data of its users. Our application functions as an SEO audit tool that processes URLs provided by the users. All interactions within the application are subject to the privacy policies of OpenAI, which can be viewed <a class="text-gray-400" target="_blank" href="https://www.openai.com/privacy">here</a>.</p>
    </div>
    <div class="mb-2">
      <h2 class="text-xl">Third-Party Services:</h2>
      <p>While our GPT's do not collect personal data, users should be aware that the URLs entered into our application are processed to evaluate website content for SEO purposes. This processing is conducted in accordance with OpenAI’s privacy policies, and no personal data is stored by our application.</p>
    </div>
    <div class="mb-2">
      <h2 class="text-xl">Changes to This Privacy Policy:</h2>
      <p>We reserve the right to update or change our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    </div>
    <div class="mb-2">
      <h2 class="text-xl">Contact Us:</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a class="text-gray-400" href="mailto:kyle@intelliminds.ai">kyle@intelliminds.ai</a>.</p>
    </div>
  </main>
</template>