import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import CharlesTichenor from '@/views/CharlesTichenor.vue'
import CameronSnow from '@/views/CameronSnow.vue'
import FelipeVergara from '@/views/FelipeVergara.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'

async function isAuthenticated() {
  if (localStorage.getItem('token') !== null) {
    const tokenStatus = await checkToken()
    return tokenStatus
  } else {
    // return false
    return true
  }
}
async function checkToken() {
  try {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    await axios.get('https://api.intelliminds.ai/api/auth/validate', { headers })
    return true
  } catch (e) {
    // return false
    return true
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      title: 'Intelliminds'
    }
  },
  {
    path: '/get-started',
    name: 'getStarted',
    component: () => import('@/views/GetStarted.vue'),
    meta: {
      title: 'Intelliminds'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/UserDashboard.vue'),
    meta: {
      title: 'Intelliminds'
    },
    children: [
      {
        path: '/interface',
        name: 'interface',
        component: () => import('@/views/ArticlesInterface.vue'),
        meta: {
          title: 'Intelliminds | Interface'
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/ContactUs.vue'),
        meta: {
          title: 'Intelliminds | Contact Us'
        }
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('@/views/UserTeam.vue'),
        meta: {
          title: 'Intelliminds | Team'
        }
      },
      {
        path: '/team/create',
        name: 'team.create',
        component: () => import('@/views/CreateBot.vue'),
        meta: {
          title: 'Intelliminds | Create'
        }
      },
      {
        path: '/team/recruit',
        name: 'team.recruit',
        component: () => import('@/views/RecruitMember.vue'),
        meta: {
          title: 'Intelliminds | Recruit'
        }
      },
      {
        path: '/chats',
        name: 'chat',
        component: () => import('@/views/UserChats.vue'),
        meta: {
          title: 'Intelliminds | Chat'
        },
        children: [
          {
            path: ':id/messages',
            name: 'chatInterface',
            props: true,
            component: () => import('@/components/ChatInterface.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/UserLogin.vue'),
    meta: {
      title: 'Intelliminds | Login'
    }
  },
  {
    path: '/password-reset',
    name: 'password',
    component: () => import('@/views/auth/PasswordReset.vue'),
    meta: {
      title: 'Intelliminds'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/UserRegister.vue'),
    meta: {
      title: 'Intelliminds | Register'
    }
  },
  {
    path: '/register/activate',
    name: 'activate',
    component: () => import('@/views/auth/EmailActivate.vue'),
    meta: {
      title: 'Intelliminds | Register'
    }
  },
  {
    path: '/register/email-verification',
    name: 'verify',
    component: () => import('@/views/auth/EmailVerify.vue'),
    meta: {
      title: 'Intelliminds | Register'
    }
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/auth/UserQuestionnaire.vue'),
    meta: {
      title: 'Intelliminds | Questions'
    }
  },
  {
    path: '/charleybot',
    name: 'charleybot',
    component: CharlesTichenor,
    meta: {
      title: 'Intelliminds | Charley Bot'
    }
  },
  {
    path: '/chat',
    name: 'charley',
    component: CharlesTichenor,
    meta: {
      title: 'Intelliminds | Charley Bot'
    }
  },
  {
    path: '/24/chat',
    name: 'cameronsnow',
    component: CameronSnow,
    meta: {
      title: 'Intelliminds | Cameron Snow'
    }
  },
  {
    path: '/25/chat',
    name: 'felipevergara',
    component: FelipeVergara,
    meta: {
      title: 'Intelliminds | Felipe Vergara'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacypolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Intelliminds | Privacy Policy'
    }
  },
  {                                                                                                                                   
    path: '/:catchAll(.*)',           
    name: 'notfound',                                         
    component: () => import('@/components/NotFound.vue'),
    meta: {
      title: 'Intelliminds'
    }                     
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            resolve({ el: element, behavior: 'smooth'});
          } else {
            reject(new Error('Element not found'));
          }
        }, 200);
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
})

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title}`

  if (window.location.hostname === 'charleybot.ai') {
    if (to.path === '/') {
      return next({ name: 'charley' })
    }

    if (to.name === 'charley') {
      return next()
    }
  }

  if (window.location.hostname === 'cameron.authorsdirectsales.com') {
    if (to.path === '/') {
      return next({ name: 'cameronsnow' })
    }

    if (to.name === 'cameronsnow') {
      return next()
    }
  }

  // Dashboard Check
  const isDashboardRoute = to.matched.some(record => record.name === 'dashboard')
  if (isDashboardRoute) {
    if (await isAuthenticated()) {
      return next()
    } else {
      return next({ name: 'login' })
    }
  }

  next()
})

export default router