<template>
  <div class="relative bg-[#F3F4F7]" style="min-height: calc(var(--vh, 1vh) * 100);">
    <div v-if="open" class="fixed inset-0 z-40 overflow-y-auto min-h-full min-w-full bg-gray-700/50">
      <div class="flex min-h-full items-center justify-center p-4 text-center" >
        <div class="relative rounded-lg bg-white px-6 py-10 sm:p-16 shadow-xl max-w-lg">
          <XMarkIcon @click="open = false" class="absolute top-4 right-4 h-6 w-6 z-50 text-gray-400 cursor-pointer"/>
          <img class="mx-auto h-[7.5rem] w-[7.5rem] rounded-full mb-4" :src=profilePicture :alt=profileAlt />
          <div class="mt-4 mb-5 sm:mt-0 sm:pt-1 text-center">
            <p class="text-xl font-bold text-gray-900 sm:text-2xl mb-1.5">Start Chat with {{ botName }}</p>
          </div>

          <p v-if="registerLoading" class="text-indigo-600">Registering Account ...</p>

          <form v-else class="space-y-2 sm:w-[360px] sm:px-6" action="#" method="POST" @submit.prevent="registerAccount">
            <div>
              <label for="name" class="sr-only">First name</label>
              <div class="mt-2">
                <input id="name" name="name" type="text" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-base sm:leading-6" v-model="name" placeholder="Your first name"/>
              </div>
            </div>

            <div>
              <label for="email" class="sr-only">Email</label>
              <div class="mt-2">
                <input id="email" name="email" type="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-base sm:leading-6" v-model="email" placeholder="Your email address" />
              </div>
            </div>

            <div>
              <button 
              type="submit" 
              class="flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
              :class="{ 'bg-gray-200 cursor-not-allowed': !formComplete, 'bg-indigo-600 hover:bg-indigo-500 cursor-pointer': formComplete }"
              >Start Chat</button>
            </div>

            <div class="relative flex items-center">
              <input type="checkbox" id="checkbox" v-model="checked" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
              <label for="checkbox" class="text-gray-400 text-[10px] ml-1.5">Accept Terms of Use and Privacy Policy.</label>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="bg-white shadow">
      <div class="max-w-screen-xl mx-auto text-black pt-3 pb-2 px-2 flex items-center justify-between">
        <div class="flex items-center sm:gap-7 gap-">
          <a class="ml-2 flex items-center gap-1" href="https://intelliminds.ai/" target="_blank">
            <img class="h-12 w-12 rounded-full p-1" :src=profilePicture :alt=profileAlt />
            <div class="w-[100px] sm:w-fit">
              <h1 class="font-semibold leading-5 text-black">{{ botName }}</h1>
              <p class="text-xs text-[#9DA3AE]">Powered by Intelliminds.ai</p>
            </div>
          </a>
          <a :href=homeUrl target="_blank"><img :src=logo alt="Business logo" class="h-10"></a>
        </div>
        <div>
          <button class="mr-2 lg:hidden" aria-label="Toggle Navigation Button" aria-expanded="false" aria-controls="menu" @click="toggleNav">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><title>Hamburger Button</title><path d="M5.66406 8.5H28.3307M5.66406 17H28.3307M5.66406 25.5H28.3307" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </button>
          <nav ref="navMenu" class="absolute top-[6.5rem] left-0 z-10 w-full bg-white h-0 overflow-hidden lg:static lg:w-auto lg:h-auto">
            <ul class="text-center text-[#2B3849] lg:flex lg:gap-3 xl:gap-5" >
              <li v-for="link in links" :key="link"><a :href=link.href target="_blank" class="mt-5 lg:mt-0 mb-1 p-2 block hover:text-[#01D0FB]">{{ link.text }}</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="bg-white max-w-screen-xl mx-auto mt-4 shadow border rounded">
      <div class="flex flex-1 flex-col px-2 py-3">
        <div class="h-full w-full transition-width flex flex-col items-stretch flex-1 mx-auto">
          <div class="mx-auto h-full w-full max-w-screen-xl">
            <div class="flex flex-col mt-2 w-[89vw] overflow-y-scroll sm:px-10 max-w-screen-xl" ref="messageContainer" style="height: calc(var(--vh, 1vh) * 75);">
              <div class="flex p-1 gap-2 text-base md:max-w-2x" v-for="message in messages" :key="message">
                <div class="w-[30px] flex flex-col relative items-end">
                  <p class="font-medium">{{ message.author_initials }}</p>
                </div>
                <div class="relative flex flex-col w-[calc(100%-50px)] gap-1">
                  <div class="break-words markdown prose ">
                    <p class="whitespace-pre-wrap">{{ message.text }}</p>
                  </div>
                </div>
              </div>
              <div v-if="typing" class="flex p-1 gap-2 text-base md:max-w-2x text-indigo-600">
                <div class="w-[30px] flex flex-col relative items-end">
                  <p class="font-medium">{{ botInitials }}</p>
                </div>
                <div class="relative flex flex-col w-[calc(100% - 50px)] gap-1">
                  <p>Typing...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref="chatInterface" class="w-full pt-2 max-w-screen-xl mx-auto">
        <form @submit.prevent="handleChat" class="flex gap-2 pb-4 px-4 sm:px-10">
          <div class="rounded-lg grow">
            <label for="message" class="sr-only">message</label>
            <div>
              <textarea 
              @keydown="checkKey" 
              :rows="rows" 
              ref="textAreaRef" 
              name="message" 
              id="message" 
              @input="handleChange"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-base sm:leading-6 max-h-[252px]" 
              placeholder="Send a message" 
              v-model="message" />
            </div>
          </div>
          <div class="flex justify-end self-center">
            <button type="submit" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import axios from 'axios'
import { Buffer } from 'buffer'

export default {
  components: {
    XMarkIcon
  },
  data() {
    return {
      botName: 'Felipe Vergara',
      logo: 'https://d30r0qbo1lg1yf.cloudfront.net/bot/25/25-logo.webp',
      profilePicture: 'https://d30r0qbo1lg1yf.cloudfront.net/bot/25/25-profile-picture.webp',
      profileAlt: 'Felipe Vergara',
      homeUrl: 'https://felipevergara.co/',
      links: [
        {
          href: 'https://felipevergara.co/curso-online/',
          text: 'Toma mi curso'
        },
        {
          href: 'https://felipevergara.co/contacto/',
          text: 'Contra mi agencia'
        },
      ],
      messages: [
        {
          author_initials: '',
          text: 'Hola! Soy Felipe-bot. ¿Cómo puedo ayudarte?'
        }
      ],
      botInitials: 'FV',
      botId: '25',
      message: '',
      typing: false,
      open: false,
      formComplete: false,
      registerLoading: false,
      accountRegistered: false
    }
  },
  setup() {
    const name = ref('')
    const email = ref('')
    const value = ref('')
    const checked = ref(false)
    const textAreaRef = ref(null)
    const rows = ref(1)

    const handleChange = (evt) => {
      const val = evt.target.value
      value.value = val
    }

    return { value, textAreaRef, rows, handleChange, name, email, checked }
  },
  watch: {
    value() {
      this.$nextTick(() => {
        const textarea = this.$refs.textAreaRef
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
        this.rows = textarea.rows
      })
    },
    name() {
      this.updateFormComplete()
    },
    email() {
      this.updateFormComplete()
    },
    checked() {
      this.updateFormComplete()
    }
  },
  mounted() {
    this.updateHeight()
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.updateHeight()
    })
  },
  created() {
    this.messages[0].author_initials = this.botInitials

    if (this.checkPreviousUser()) {
      this.getPreviousMessages()
    }
    window.addEventListener('resize', () => {
      this.updateHeight()
    })
  },
  methods: {
    updateHeight() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    checkPreviousUser() {
      if (localStorage.getItem(`${this.botId}_chat_id`) !== null && localStorage.getItem(`${this.botId}_temp_auth`) !== null) {
        this.accountRegistered = true
        return true
      }
      return false
    },
    checkKey(evt) {
      if (!this.isMobileDevice()) {
        if (evt.key === 'Enter' && !evt.shiftKey) {
          evt.preventDefault()
          this.handleChat()
        }
      }
    },
    scrollMessages() {
      this.$nextTick(() => {
        let element = this.$refs.messageContainer
        element.scrollTop = element.scrollHeight
      })
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    validateEmail(email) {
      let match = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return match.test(email)
    },
    updateFormComplete() {
      if (this.name.length > 2 && this.validateEmail(this.email) && this.checked) {
        this.formComplete = true
      } else {
        this.formComplete = false
      }
    },
    handleChat() {
      if (!this.accountRegistered && this.message.trim().length > 0) {
        this.open = true
      } else {
        if (this.message.trim().length > 0) {
          this.sendChat(this.message)
          this.renderMessage()
        }
      }
    },
    encodeEmail(email) {
      const credentials = `${email}`
      const credentialsEncoded = encodeURI(credentials)
      const encoded = Buffer.from(credentialsEncoded).toString('base64')
      localStorage.setItem(`${this.botId}_temp_auth`, encoded)
    },
    renderMessage() {
      this.messages.push({ text: this.message, author_initials: 'ME' })
      this.scrollMessages()
      
      setTimeout(() => {
          this.typing = true
          this.scrollMessages()
        }, 1000)

      this.message = ''
    },
    toggleNav() {
      this.$refs.navMenu.classList.toggle('nav-menu--show')
    },
    async getPreviousMessages() {
      try {
        const headers = {
          'Authorization': `Email ${localStorage.getItem(`${this.botId}_temp_auth`)}`
        }
        const res = await axios.get(`https://api.intelliminds.ai/api/chat/${localStorage.getItem(`${this.botId}_chat_id`)}/messages/`, { headers })
        this.messages = res.data
      } catch(e) {
        console.log(e)
      }
      this.scrollMessages()
    },
    async registerAccount() {
      if (this.validateEmail(this.email) && this.name.length > 2 && this.checked) {
        try {
          this.registerLoading = true

          const headers = {
            'content-type': 'application/json'
          }
          const data = {
            email: this.email.trim(),
            first_name: this.name.trim()
          }
  
          await axios.post(`https://api.intelliminds.ai/api/auth/temporary_register`, data, { headers })
  
          this.encodeEmail(this.email)
          const currentMessage = this.message
          this.createChat()       
          .then(() => this.sendChat(currentMessage))
          this.renderMessage()
          this.accountRegistered = true
          this.open = false
        } catch(e) {
          console.log(e)
        }
      }

    },
    async createChat() {
      try {
        const headers = {
          'Authorization': `Email ${localStorage.getItem(`${this.botId}_temp_auth`)}`
        }
        const data = {
          bots: [this.botId]
        }

        const res = await axios.post('https://api.intelliminds.ai/api/chat/', data, { headers })
        localStorage.setItem(`${this.botId}_chat_id`, res.data.id)
      } catch(e) {
        console.log(e)
      }
    },
    async sendChat(message) {
      const textarea = this.$refs.textAreaRef
      textarea.style.height = '36px'

      try {
        const headers = {
          'Authorization': `Email ${localStorage.getItem(`${this.botId}_temp_auth`)}`
        }
        const data = {
          text: message
        }

        const res = await axios.post(`https://api.intelliminds.ai/api/chat/${localStorage.getItem(`${this.botId}_chat_id`)}/messages/`, data, { headers })

        this.messages.push({ text: res.data[0].text, author_initials: res.data[0].author_initials })  
        this.typing = false
        this.scrollMessages()
      } catch(e) {
        console.log(e)
      }
    }
  }
}
</script>

<style>
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}
nav {
  transition: height 0.7s ease-in-out;
}
.nav-menu--show {
  height: calc(var(--vh, 1vh) * 91);
}

@media only screen and (min-width: 1000px) {
  .nav-menu--show {
    height: auto;
  }
}
</style>